<template>
  <div>
    <b-card :class="`${is_detail_network ? 'border-box-analytics' : ''} card-twitter-average`">
      <b-card-header class="">
        <b-card-title>{{$t('twitter.averageRet')}}</b-card-title>
      </b-card-header>
      <b-card-body >
        <b-row class="d-flex flex-wrap p-0">
          <b-col class="col-12 p-0 col-md-3 text-center text-md-left">

            <div class="col-12 mb-3" v-if="retweet_avg.value !== 0">              
              <span class="d-block"><strong class="h3">{{utils.separatebycomma(retweet_count_avaible.value)}}</strong> tweets</span>
              <div>
                <feather-icon size="18" icon="ArrowUpIcon" v-if="retweet_avg.value > 0" class="text-success"/>
                <feather-icon size="18" icon="ArrowDownIcon" v-else/> 
                <strong>{{retweet_avg.percentage}} %</strong>  {{$t('twitter.last')}} {{retweet_avg.performance}} {{$t('twitter.days')}}
              </div>
            </div>

            <div class="col-12 mb-3" v-if="reply_avg.value !== 0">
              <p><strong class="h4">{{$t('twitter.averageRes')}}</strong></p>

              <span class="d-block"><strong class="h3">{{utils.separatebycomma(reply_count_avaible.value)}}</strong> {{$t('twitter.responses')}}</span>
              <div>
                <feather-icon size="18" icon="ArrowUpIcon" v-if="reply_avg.value > 0" class="text-success"/>
                <feather-icon size="18" icon="ArrowDownIcon" v-else/> 
                <strong>{{reply_avg.percentage}} %</strong>  {{$t('twitter.last')}} {{reply_avg.performance}} {{$t('twitter.days')}}
              </div>
            </div>

            <div class="col-12 mb-3" v-if="favorite_avg.value !== 0">
              <p><strong class="h4">{{$t('twitter.averageFav')}}</strong></p>

              <span class="d-block"><strong class="h3">{{utils.separatebycomma(favorite_count_avaible.value)}}</strong> {{$t('twitter.favorites')}}</span>
              <div>
                <feather-icon size="18" icon="ArrowUpIcon" v-if="favorite_avg.value > 0" class="text-success"/>
                <feather-icon size="18" icon="ArrowDownIcon" v-else/> 
                <strong>{{favorite_avg.percentage}} %</strong>  {{$t('twitter.last')}} {{favorite_avg.performance}} {{$t('twitter.days')}}
              </div>
            </div>

          </b-col>
          <b-col class="col-12 col-md-9">
            <apexchart
              type="bar"
              height="350"
              :options="chartOptions"
              :series="series"
              :key="show"
            ></apexchart>
          </b-col>
        </b-row>
      </b-card-body>

    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle
} from "bootstrap-vue";
import apexchart from "vue-apexcharts";
import utils from "@/libs/utils";

export default {
  components: {
    BRow,
    apexchart,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle
  },
  props: {
    audienceData: {
      type: Object
    },
    is_detail_network: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    retweet_avg() {
      return this.getting_object_porcentage(this.audienceData.retweet_avg.performance)
    },
    reply_avg() {
      return  this.getting_object_porcentage(this.audienceData.reply_avg.performance)
    },
    favorite_avg() {
      return this.getting_object_porcentage(this.audienceData.favorite_avg.performance)
    },
    reply_count() {
      return utils.getSeries(utils.convertData(this.audienceData.reply_count.performance),'value')
    },
    retweet_count() {
      return utils.getSeries(utils.convertData(this.audienceData.retweet_count.performance),'value')
    },
    favorite_count() {
      return utils.getSeries(utils.convertData(this.audienceData.favorite_count.performance),'value')
    },
    reply_count_avaible() {
      return utils.getAvaibleData(this.audienceData.reply_count.performance)
    },
    retweet_count_avaible() {
      return utils.getAvaibleData(this.audienceData.retweet_count.performance)
    },
    favorite_count_avaible() {
      return utils.getAvaibleData(this.audienceData.favorite_count.performance)
    },
  },
  methods: {
    percentage(data) {
      return utils.getPercentagePerformance(data)
    },
    getting_object_porcentage(data_performance) {
      const new_array = []
      for (const property in data_performance) {
        new_array.push(data_performance[property].value)
      }
      const object_to_return = utils.getAvaibleData(data_performance)
      object_to_return['percentage'] = this.percentage(new_array)[object_to_return['index']]
      return object_to_return
    }
  },
  created() {  
    for (let i = 0; i < 3; i++) {
      this.series.map((item, index) => {
        if (index === 0) {
          item.data.push(this.reply_count[i])
        } else if (index === 1) {
          item.data.push(this.favorite_count[i])
        } else if (index === 2) {
          item.data.push(this.retweet_count[i])
        }
      })
    }
  },
  data() {
    return {
      days: [30, 90, 180],
      show: false,
      utils,
      series: [
        {
          name: "Respuestas",
          data: [],
        },
        {
          name: "Favoritos",
          data: [],
        },
        {
          name: "Tweets",
          data: [],
        },
      ],
      chartOptions: {
        tooltip: {
          custom: ({seriesIndex, dataPointIndex, w}) => {
            return (
              `<div class="width-tooltip-graph">
                  <div class="d-block bg-toottip-graph p-1">
                    ${this.$t(`twitter.average_tweets_tooltip_${seriesIndex}`)}${this.days[dataPointIndex]} ${this.$t('twitter.days')}.
                  </div>
                  <div class="p-1">
                    <strong class="mr-tooltip-graph">${this.series[seriesIndex].name}: 
                    </strong> ${utils.separatebycomma(w.globals.initialSeries[seriesIndex].data[dataPointIndex])} 
                  </div>
                </div>`
            )
          }
        },
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ['#233675', '#5A60EE', '#08C3C3'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 100,
          },
        },
        xaxis: {
          type: "String",
          categories: [`30 ${this.$t('twitter.days')}`, `90 ${this.$t('twitter.days')}`, `180 ${this.$t('twitter.days')}`],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
      data: {
        data: {},
      },
    };
  },

  watch: {
    "$i18n.locale"() {
      this.show = !this.show;
      this.series[0].name = "Responses";
      this.series[1].name = "Favorites";
      this.series[2].name = "Tweets";
      this.chartOptions.xaxis.categories = [`30 ${this.$t('twitter.days')}`, `90 ${this.$t('twitter.days')}`, `180 ${this.$t('twitter.days')}`]
    },
  },
};
</script>
