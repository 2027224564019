<template>
  <b-row ref="shortPrint">
    <!-- First column -->
    <b-col cols="12" sm="12" md="12" lg="12" xl="4">
      <b-row>

        <b-col cols="12">
          <b-row class="justify-content-md-center"  align-h="center">
            <b-col cols="6" sm="4" md="4" lg="4" xl="5" class="mt-2 text-center">
              <b-avatar rounded :src="avatar" variant="light" badge badge-offset="-1.5em" badge-variant="white" class="w-third float-image">
                <template #badge>
                  <i :class="`${utils.getClassNetworkSpan('youtube')} margin-icon font-30`"/>
                </template>
              </b-avatar>
              <b-img class="show-image-sm" :src="require('@/assets/images/logo/logo-brandme1.png')"/>
            </b-col>
          </b-row>
        </b-col>
        
        <b-col cols="12" class="mt-10">
          <h1 class="text-center summary-username">@{{data.report.basic.username}}</h1>
        </b-col>

        <b-col cols="12">
          <h1 class="text-center summary-title">{{data.report.basic.title}}</h1>
        </b-col>

        <b-col cols="12">
          <b-card no-body class="no-shadow">
            <b-card-header>
              <div class="text-center w-full">
                <b-card-title><h1 class="mb-25 font-weight-bolder">{{suscribers}}</h1></b-card-title>
                <b-card-text><h3>{{$t('campaigns.followers')}}</h3></b-card-text>
              </div>
            </b-card-header>
          </b-card>
        </b-col>

        <b-col cols="12">
          <statistic-card-horizontal
            class="no-shadow"
            icon="ActivityIcon"
            color="success"
            :statistic="cqsText"
            :statistic-title="$t('youtube.channelQuality')"
          />
        </b-col>

        <b-col cols="12">
          <statistic-card-horizontal
            class="no-shadow"
            icon="EyeIcon"
            color="success"
            :statistic="utils.getFormat(data.report.metrics.views_count.value)"
            :statistic-title="$t('youtube.visualizations')"
          />
        </b-col>
      </b-row>
    </b-col>
    <!-- End First column -->

    <!-- Second column -->
    <b-col cols="12" sm="12" md="12" lg="12" xl="4">
      <b-row>
        <b-col cols="12">
          <b-card no-body class="no-shadow">
            <b-card-header>
              <b-card-title class="subtitle-summary">{{$t('youtube.viewRate')}}</b-card-title>
            </b-card-header>
            <b-card-body class="pb-0">
              <div class="d-flex justify-content-start mb-3">
                <div class="mr-2">
                  <b-card-text class="mb-50">
                    {{$t('youtube.views')}}
                  </b-card-text>
                  <h3 class="font-weight-bolder">
                    <span class="text-primary">{{ utils.getFormat(data.report.metrics.views_count.value) }}</span>
                  </h3>
                </div>
                <div>
                  <b-card-text class="mb-50">
                    {{$t('youtube.averageViews')}}
                  </b-card-text>
                  <h3 class="font-weight-bolder">
                    <span>{{ utils.getFormat(data.report.metrics.views_avg.performance.all.value) }}</span>
                  </h3>
                </div>
              </div>
              <vue-apex-charts
                v-if="showGraph"
                :key="showGraph"
                type="line"
                height="240"
                :options="options"
                :series="[{name: $t('youtube.views'), data: seriesViews}, {name: $t('youtube.averageViews'), data: seriesViewsAvg}]"
              />
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card no-body class="no-shadow">
            <b-card-header>
              <b-card-title class="subtitle-summary">Likes/Dislikes</b-card-title>
            </b-card-header>
            <b-card-body class="pb-0">
              <div class="d-flex justify-content-start mb-3">
                <div class="mr-2">
                  <b-card-text class="mb-50">
                    Likes
                  </b-card-text>
                  <h3 class="font-weight-bolder">
                    <span class="text-primary">{{ utils.getFormat(data.report.metrics.likes_count.performance.all.value) }}</span>
                  </h3>
                </div>
                <div>
                  <b-card-text class="mb-50">
                    Dislikes
                  </b-card-text>
                  <h3 class="font-weight-bolder">
                    <span>{{ utils.getFormat(data.report.metrics.dislikes_count.performance.all.value) }}</span>
                  </h3>
                </div>
              </div>
              <vue-apex-charts
                v-if="showGraph"
                :key="showGraph"
                type="line"
                height="240"
                :options="options"
                :series="[{name: 'Likes', data: seriesLikes}, {name: 'Dislikes', data: seriesDislikes}]"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <!-- End second column -->

    <!-- Third column -->
    <b-col cols="12" sm="12" md="12" lg="12"  xl="4">
      <b-row>
        <b-col cols="12">
          <b-card no-body class="no-shadow">
            <b-card-header>
              <b-card-title class="subtitle-summary">{{$t('campaigns.followers')}}</b-card-title>
            </b-card-header>
            <b-card-body class="pb-0">
              <div class="d-flex justify-content-start mb-3">
                <div class="mr-2">
                  <b-card-text class="mb-50">
                    {{$t('youtube.duringlast')}}
                  </b-card-text>
                  <h3 class="font-weight-bolder">
                    <span class="text-primary">{{$t('youtube.total')}} : {{utils.getFormat(data.report.metrics.subscribers_count.performance.all.value)}}</span>
                  </h3>
                </div>
              </div>
              <vue-apex-charts
                v-if="showGraph"
                :key="showGraph"
                type="line"
                height="240"
                :options="options"
                :series="[{name: $t('youtube.views'), data: seriesSubs}]"
              />
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="12" v-if="data.report.features.audience_geo.data.length > 0">
          <b-card no-body class="no-shadow">
            <b-card-header>
              <b-card-title class="subtitle-summary">
                {{$t('instagram.mainCountries')}}
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <vue-apex-charts v-if="showGraph" :key="showGraph" type="bar" height="300" :options="countryOptions" :series="[{name: $t('youtube.countries'), data: countriesSeries}]"></vue-apex-charts>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" v-if="!printing && !is_web" class="text-right">
      <b-button
        variant="primary"
        class="hide-sm"
        @click="print()"
      >
        {{$t('printSummary')}}
      </b-button>
    </b-col>

  </b-row>
</template>
<script>
import { BRow, BCol, BCard, BCardTitle, BCardHeader, BCardText, BButton, BCardBody, BAvatar, BImg } from 'bootstrap-vue'
import utils from '@/libs/utils'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import VueApexCharts from 'vue-apexcharts';
import { print } from '@/libs/utils/others';

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    avatar: {
      type: String,
      default: () => ''
    },
    format: {
      type: String,
      default: 'pdf'
    },
    is_web: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BRow,
    BCol,
    BAvatar,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardText,
    BButton,
    BCardBody,
    StatisticCardHorizontal,
    VueApexCharts,
    BImg
  },
  data () {
    return {
      utils,
      printing: false,
      showGraph: false,
      options: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0, 12],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#d0ccff', '#ebe9f1'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#d0ccff', '#ebe9f1'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: ['30d', '90d', '180d', 'all'],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(0)}k` : val
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
      print_image: require('@/assets/images/logo/logo_print.png'),
      src_img: '',
      interval_loading: null
    }
  },
  created() {
    const self = this;
    setTimeout(function() {
      self.showGraph = true;
      if (!self.is_web) self.print()
    }, 100);
    
    utils.urlImagetoBase64(this.print_image, function (result) {
      self.src_img = result
    });
  },
  methods: {
    quitAll(data) {
      const arr = utils.convertData(data)
      arr.pop()
      return arr
    },
    print() {
      this.printing = true;
      const self = this;
      this.$emit('toogleIconClose', true)
      const name_file = `${self.$t('profile.summary')}_${self.data.report.basic.username}_${self.$route.params.network}_BrandMe`
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
      setTimeout(function() {
        print('l', name_file, self.$parent.$parent.$refs.body, function () {
          self.printing = false;
          clearInterval(self.interval_loading)
          self.$vs.loading.close()
          self.$emit('toogleIconClose', false)
          self.$emit('closeModal')
        }, self.format)
      }, 500);
    }
  },
  computed: {
    suscribers() {
      return utils.getFormat(Math.round(this.data.metrics_history.subscribers_count.value))
    },
    cqsText() {
      return `${this.data.report.features.cqs.data.value} - ${this.$t(`instagram.${this.data.report.features.cqs.data.display_mark}`)}`
    },
    seriesLikes() {
      return utils.getSeries(this.quitAll(this.data.report.metrics.likes_count.performance),'value')
    },
    seriesDislikes() {
      return utils.getSeries(this.quitAll(this.data.report.metrics.dislikes_count.performance),'value')
    },
    seriesViews() {
      return utils.getSeries(this.quitAll(this.data.report.metrics.views_count.performance),'value')
    },
    seriesViewsAvg() {
      return utils.getSeries(this.quitAll(this.data.report.metrics.views_avg.performance),'value')
    },
    seriesSubs() {
      return this.utils.getSeries(this.quitAll(this.data.report.metrics.subscribers_count.performance),'value')
    },
    countryOptions() {
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#4be3ac',
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '30%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.data.report.features.audience_geo.data.map(item => { return utils.getCountry(item.title ? item.title : item.id ? item.id : item.code) }),
          show: true,
          labels: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        }
      }
      return options
    },
    countriesSeries() {
      const serie = this.data.report.features.audience_geo.data.map(item => { return item.prc })
      return serie
    },
  }
}
</script>

<style type="text/css">
  .no-shadow {
    box-shadow: none;
  }
</style>
