<template>
  <b-row ref="shortPrint">
    <!-- First column -->
    <b-col cols="12" sm="12" md="12" lg="12" xl="3">
      <b-row>

        <b-col cols="12">
          <b-row class="justify-content-md-center" align-h="center">
            <b-col cols="6" sm="4" md="4" lg="4" xl="5" class="mt-2 text-center">
              <b-avatar rounded :src="avatar" variant="light" badge badge-offset="-1.5em" badge-variant="white" class="w-third float-image">
                <template #badge>
                  <i :class="`${utils.getClassNetworkSpan('tiktok')} margin-icon font-30`"/>
                </template>
              </b-avatar>
              <b-img class="show-image-sm" :src="require('@/assets/images/logo/logo-brandme1.png')"/>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" class="mt-10">
          <h1 class="text-center summary-username">@{{data.report.basic.username}}</h1>
        </b-col>
        <b-col cols="12">
          <h1 class="text-center summary-title">{{data.report.basic.title}}</h1>
        </b-col>

        <b-col cols="12">
          <b-card no-body class="no-shadow mt-2">
            <b-card-body class="d-flex justify-content-center align-items-center">
              <div class="text-center">
                <b-avatar size="40px" rounded class="bg-primary bg-lighten-2 mb-1">
                  <feather-icon size="20" :icon="'UserIcon'"></feather-icon>
                </b-avatar>
                <div class="profile-user-info">
                  <h2 class="mb-1 font-weight-bolder">
                    {{ subs }}
                  </h2>
                  <b-card-text class="text-muted mt-1"><h4>{{$t('campaigns.followers')}}</h4></b-card-text>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card no-body class="no-shadow">
            <b-card-body class="d-flex justify-content-center align-items-center">
              <div class="text-center">
                <b-avatar size="40px" rounded class="mr-1 bg-success bg-lighten-2 mb-1">
                  <feather-icon size="20" :icon="'VideoIcon'"></feather-icon>
                </b-avatar>
                <div class="profile-user-info">
                  <h2 class="mb-1 font-weight-bolder">
                    {{ qs }}
                  </h2>
                  <b-card-text class="text-muted mt-1"><h4>{{$t('tiktok.subQuality')}}</h4></b-card-text>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card no-body class="no-shadow">
            <b-card-body class="d-flex justify-content-center align-items-center">
              <div class="text-center">
                <b-avatar size="40px" rounded class="mr-1  bg-success bg-lighten-2 mb-1">
                  <feather-icon size="20" :icon="'BookOpenIcon'"></feather-icon>
                </b-avatar>
                <div class="profile-user-info">
                  <h2 class="mb-1 font-weight-bolder">
                    {{ ar }}
                  </h2>
                  <b-card-text class="text-muted"><h4>{{$t('tiktok.audienceAcc')}}</h4></b-card-text>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <!-- End First column -->

    <b-col cols="12" sm="12" md="12" lg="12" xl="9">
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="6">
          <b-card no-body class="no-shadow h-auto">
            <b-card-header class="d-flex align-items-stretch">
              <div class="col-7 p-0 flex-grow-1 d-flex flex-column align-items-start">
                
                <b-card-title class="subtitle-summary ">{{$t('instagram.ageRange')}} </b-card-title>
                <div  class="d-flex justify-content-start mb-3">
                  <div class="mr-2">
                    <b-card-text class="mb-50">
                      {{$t('instagram.female')}}
                    </b-card-text>
                    <h3 class="font-weight-bolder">
                      <span style="color:#E75480">{{ gendersTotal[1] }}%</span>
                    </h3>
                  </div>
                  <div class="mr-2">
                    <b-card-text class="mb-50">
                      {{$t('instagram.male')}}
                    </b-card-text>
                    <h3 class="font-weight-bolder">
                      <span style="color:#5cb3ff">{{ gendersTotal[0] }}%</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-5 mt-07 p-0 d-flex flex-column align-items-end">
                <div>
                  <h5 class="m-0">{{$t('twitter.engagementRate')}}</h5>
                  <h3 class="m-0 mt-2">{{er}}</h3>
                  <p class="d-flex align-items-center m-0">
                    <span :class="`dot-profile-tiktok ${utils.getColorDotProfile(data.report.metrics.er.mark_title.toLowerCase())}`" />
                    {{$t(`tiktok.${data.report.metrics.er.mark_title.toLowerCase()}`)}} 
                  </p>
                </div>
              </div>
            </b-card-header>
            <b-card-body class="pb-0">
              <vue-apex-charts type="line" height="200" :options="chartOptions" :series="series" :key="showGraph" v-if="showGraph"></vue-apex-charts>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col sm="12" md="12" lg="12" xl="6">
          <b-card no-body class="no-shadow">
            <b-card-header>
              <b-card-title class="subtitle-summary">
                {{$t('instagram.mainCountries')}}
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <vue-apex-charts type="bar" height="300" :options="countryOptions" :series="[{name: $t('youtube.countries'), data: countriesSeries}]" :key="showGraph" v-if="showGraph"></vue-apex-charts>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col sm="12" md="12" lg="12" xl="12">
          <b-card no-body class="no-shadow">
            <b-card-header>
              <b-card-title class="subtitle-summary">{{$t('tiktok.viewsAndLikes')}}</b-card-title>
            </b-card-header>
            <b-card-body class="pb-0">
              <vue-apex-charts type="line" height="400" :options="chartOptionsLikes" :series="seriesLikes" :key="showGraph" v-if="showGraph"></vue-apex-charts>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" v-if="!printing && !is_web" class="text-right">
      <b-button
        variant="primary"
        class="hide-sm"
        @click="print()"
      >
        {{$t('printSummary')}}
      </b-button>
    </b-col>

  </b-row>
</template>
<script>
import { BRow, BCol, BButton, BCard, BCardBody, BAvatar, BCardText, BCardTitle, BCardHeader, BImg } from 'bootstrap-vue'
import utils from '@/libs/utils'
import VueApexCharts from 'vue-apexcharts';
import { print } from '@/libs/utils/others';

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    avatar: {
      type: String,
      default: () => ''
    },
    format: {
      type: String,
      default: 'pdf'
    },
    is_web: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BAvatar,
    BCardText,
    BCardTitle,
    BCardHeader,
    VueApexCharts,
    BImg
  },
  data () {
    return {
      utils,
      printing: false,
      showGraph: false,
      chartOptions: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#5cb3ff', '#E75480'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#5cb3ff', '#E75480'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          title: {
            text:`${this.$t('instagram.ageRange')}`,
            offsetY: -10,
            style: {
              fontWeight: 400,
              fontSize: '1rem',
              color: '#b9b9c3'
            }
          },
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64'],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              return `${val}%`
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
      print_image: require('@/assets/images/logo/logo_print.png'),
      src_img: '',
      interval_loading: null
    }
  },
  created() {
    const self = this;
    setTimeout(function() {
      self.showGraph = true;
      if (!self.is_web) self.print()
    }, 100);

    utils.urlImagetoBase64(this.print_image, function (result) {
      self.src_img = result
    });
  },
  methods: {
    capitalize (str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1, str.length)
    },
    print() {
      this.printing = true;
      const self = this;
      this.$emit('toogleIconClose', true)
      const name_file = `${self.$t('profile.summary')}_${self.data.report.basic.username}_${self.$route.params.network}_BrandMe`
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
      setTimeout(function() {
        print('l', name_file, self.$parent.$parent.$refs.body, function () {
          self.printing = false;
          clearInterval(self.interval_loading)
          self.$vs.loading.close()
          self.$emit('toogleIconClose', false)
          self.$emit('closeModal')
        }, self.format)
      }, 500);
    }
  },
  computed: {
    genders() {
      const obj = []
      if (this.data.report.features.audience_age_gender && this.data.report.features.audience_age_gender.data) {
        const keys = Object.keys(this.data.report.features.audience_age_gender.data)

        for (let index = 0; index < keys.length; index++) {
          obj.push({male: this.data.report.features.audience_age_gender.data[keys[index]].male, female: this.data.report.features.audience_age_gender.data[keys[index]].female})
        }
      }

      return obj
    },
    subs() {
      return utils.getFormat(this.data.report.metrics.subscribers_count.value)
    },
    qs() {
      if (this.data.report.metrics.subscribers_quality) return `${utils.numberFormat(this.data.report.metrics.subscribers_quality.value)}% ${this.$t(`instagram.${this.capitalize(this.data.report.metrics.subscribers_quality.mark)}`)}`
      return 'No hay datos'
    },
    ar() {
      if (this.data.report.metrics.audience_reachability) return `${utils.numberFormat(this.data.report.metrics.audience_reachability.value)}% ${this.$t(`instagram.${this.capitalize(this.data.report.metrics.audience_reachability.mark)}`)}`
      return 'No hay datos'
    },
    er() {
      if (this.data.report.metrics.er) return `${utils.decimalFixed(this.data.report.metrics.er.value, 2)}%`
      return 'No hay datos'
    },
    male() {
      const male = []
      this.genders.map(item => male.push(item.male))
      return male
    },
    female() {
      const female = []
      this.genders.map(item => female.push(item.female))
      return female
    },
    gendersTotal() {
      const reduceMale = (sum, item) => sum + item.male
      const reduceFemale = (sum, item) => sum + item.female
      const male = this.genders.reduce(reduceMale, 0)
      const female = this.genders.reduce(reduceFemale, 0)
      return [Math.round(male), Math.round(female)]
    },
    series() {
      return [{name: `${this.$t('instagram.male')}`, data: this.male}, {name: `${this.$t('instagram.female')}`, data: this.female}]
    },
    chartOptionsLikes() {
      const chart = {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#E75480', '#67dab9'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#E75480', '#67dab9'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          title: {
            text: 'Video Ids',
            offsetY: -10,
            style: {
              fontWeight: 400,
              fontSize: '1rem',
              color: '#b9b9c3'
            }
          },
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              const id = `${val}0`
              return `${id.substr(0,5)}...`
            },
          },
          axisTicks: {
            show: false,
          },
          categories: [],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              return utils.getFormat(val)
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      }
      chart.xaxis.categories = utils.getCategories(this.data.report.features.blogger_views_likes_chart.data, 'id')
      return chart
    },
    countryOptions() {
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#4be3ac',
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '30%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.data.report.features.audience_geo.data.countries.map(item => { return utils.getCountry(item.title ? item.title : item.id ? item.id : item.code) }),
          show: true,
          labels: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        }
      }
      return options
    },
    seriesLikes() {
      const serie = [{name: 'likes', data: []}, { name: `${this.$t('youtube.views')}`, data: []}]

      serie[0].data = utils.getSeries(this.data.report.features.blogger_views_likes_chart.data, 'y')
      serie[1].data = utils.getSeries(this.data.report.features.blogger_views_likes_chart.data, 'x')
      return serie
    },
    countriesSeries() {
      const serie = this.data.report.features.audience_geo.data.countries.map(item => { return item.prc })
      return serie
    },
  }
}
</script>

<style type="text/css">
  .no-shadow {
    box-shadow: none;
  }
</style>
<style scoped>
.dot-profile-tiktok {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin: 0 0.3em 0 0;
}
.mt-07 {
  margin-top: 0.7em;
}
</style>